import b2cauth from 'react-azure-adb2c';
import decodeJWT from 'jwt-decode';

window.decodeJWT = function (token) {
    if (!token) {
        console.error('❌ No token provided for decoding.');
        return null;
    }
    return decodeJWT(token);
};

class AuthB2C {
    isLoggedIn() {
        debugger;
        if (b2cauth.getAccessToken()) {
            return true;
        }

        return false;
    }

    logout() {
        b2cauth.signOut();
        sessionStorage.clear('token');
    }

    getToken(is401 = false) {
        if (401) {
            // doesn't refresh the token if stale do force it to
            // alert('gettoken 401');
            //    sessionStorage.clear('token');
            // b2cauth.acquireToken();
        }

        return b2cauth.getAccessToken();
    }

    userInfo() {
        const decoded = decodeJWT(b2cauth.getAccessToken());
        var claims = {};

        Object.keys(decoded).forEach((key) => {
            if (key.indexOf('extension_') === 0) {
                claims[key.replace('extension_', '')] = decoded[key];
            }
        });

        return { user: { claims: claims, ...decoded } };
    }

    currentUser() {
        const decoded = decodeJWT(b2cauth.getAccessToken());

        var cla = this.userInfo();
        return {
            name: decoded.name,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            emails: decoded.emails,
            city: decoded.city,
            country: decoded.country,
        };
    }
}

export default AuthB2C;
