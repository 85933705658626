import * as React from 'react';
import { Dropdown } from 'office-ui-fabric-react';
import { buildClassName, deepCopy } from './formFunctions';
import { findLists, parseBool } from '../../services/globalMethods';
import { BpSpinner } from '../BpSpinner';
import { substitute } from '../../services/stringsubstitutions';
import { setDataStore } from '../../redux/actions';

/*
            - type: dropDown
              property: resourceGroupId
              header: Resource Group
              + options: organisation.resourceGroups
              + map: 
                  key: resourceGroupId
                  text: name
*/

export const FormDropDown = ({
    field,
    additional,
    formGenerator,
    formGenerator: {
        state: { form },
    },
    collectionStack,
}) => {
    var copyStack = deepCopy(collectionStack);
    copyStack.reverse();

    if (field.settings.options == null) debugger;

    const controller = additional.controller;

    field.property = substitute(field.property, controller, copyStack);
    field.header = substitute(field.header, controller, copyStack);
    field.placeholder = substitute(field.placeholder, controller, copyStack);

    try {
        var { decode, onChange } = additional;
        if (field.settings.options == null && field.items != null) {
            field.settings.options = field.items;
        }

        var options = [];
        if (Array.isArray(field.settings.options)) options = field.settings.options;
        else {
            var stripTemplateString = (str) => {
                return str.trim().replace(/^\${|\}$/g, '');
            };

            field.settings.options = stripTemplateString(field.settings.options);
            field.settings.options = substitute(field.settings.options, controller, copyStack);
            options = formGenerator.props.controller.props.dataStore.get(field.settings.options);
        }

        if (!options) {
            options = findLists(formGenerator.props.controller.props.page, field.settings.options);
        }

        if (!options) return <BpSpinner />;

        // require as if there is no data then the ?form=annotation throws error
        if (options == null) {
            return null;
        }

        var isStringArray = typeof options[0] === 'string';
        if (field.settings.stringArray || isStringArray)
            options = options.map((p) => {
                return { key: p, text: p };
            });

        if (field.settings.map) {
            options = options.map((p) => {
                var text = null;
                field.settings.map.text.split('|').forEach((item) => {
                    if (text != null) return;

                    if (p[item] != null && p[item].trim() !== '') text = item;
                });

                if (text == null) text = field.settings.map.text;

                return { key: p[field.settings.map.key], text: p[text], parent: p };
            });
        }

        if (parseBool(field.settings.addPlaceholderToOptions, false) && field.placeholder) {
            var key = field.settings.addPlaceholderToOptionsValue || null;
            if (options.length === 0 || options[0].key !== key) {
                options.unshift({ key: field.settings.addPlaceholderToOptionsValue || null, text: field.placeholder });
            }
        }

        var getValue = () => {
            var formData2 = formGenerator.getFormData();
            var val = formData2[field.property];
            if (val != null) {
                val = val.toString();
            }

            return val;
        };

        var decode2 = (p1, p2) => {
            var result = decode(p1, p2);
            return result;
        };

        const getDropdownStyles = () => {
            return {
                // dropdown: [{ borderColor: 'red' }],
                // // dropdownItem: { borderColor: 'red' },
                // dropdownItems: { borderColor: 'red' },
                // dropdownOptionText: { borderColor: 'red' },
                // dropdown: {
                //     root: {
                //         span: { borderColor: 'red' },
                //     },
                //     options: { borderColor: 'red' },
                // },
                // subComponentStyles: {
                //     panel: { borderColor: 'red' },
                // },
                root: {
                    width: '100%',
                    // label: { borderColor: 'red' },
                    // dropdown: [{ borderColor: 'red' }],
                    // dropdown: { borderColor: 'red' },
                    // dropdown: { borderColor: themes(theme, formId)('textFieldBorder') },
                },
            };
        };

        var defaultValue = null;
        var formData = formGenerator.getFormData();

        if (formData[field.property] == null && field.defaultValue != null) {
            defaultValue = substitute(field.defaultValue, controller, [controller.props.dataStore, copyStack]);
            if (defaultValue !== '') onChange(field, defaultValue);
        }

        const parentName = field.property + 'Parent';

        return (
            <Dropdown
                id={field.id}
                className={buildClassName(formGenerator, field, 'form-dropdown', field.class, collectionStack)}
                style={field.styles}
                styles={getDropdownStyles}
                options={options}
                label={substitute(field.header, controller, [field])}
                disabled={decode2(field, field.disabled)}
                required={parseBool(field.required, false)}
                placeholder={field.placeholder}
                value={getValue}
                defaultSelectedKey={formData[field.property] || defaultValue}
                onChange={(e, item) => {
                    // if (field.onChange) {
                    //     console.log(item.key);
                    //     formGenerator.runActions(field, field.onChange, item.key, formData, copyStack);
                    //     formGenerator.updateFormData(formData);
                    // }
                    var ds = {};
                    ds[parentName] = item.parent;
                    controller.props.dispatch(setDataStore(controller, ds));
                    // formGenerator.updateFormData(formData);
                    onChange(field, item.key);
                }}
                selectedKey={formData[field.property]}
                selectedKeys={null}
            />
        );
    } catch (error) {
        return 'ERROR: Have you set field.settings.options?';
    }
};
