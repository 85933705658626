import * as React from 'react';
import { connect } from 'react-redux';

import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Controller from './Controller';
import { pageChanged, setSearch } from '../../redux/actions';
import ContextPanel from './ContextPanel';

class PortalMain extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        // this.backListener = this.props.history.listen((location) => {
        //     if (this.props.history.action === 'POP') {
        //         this.props.dispatch(pageChanged(location.pathname + location.search, true));
        //          this.props.dispatch(setSearch(this.props.search));
        //     }
        // });
    }

    componentWillUnmount() {
        if (this.backListener && typeof this.backListener == 'function') {
            this.backListener();
        }
    }
    render() {
        var key = this.props.location.pathname;
        return (
            <>
                {/* <ContextPanel /> */}
                <Switch>
                    <Route path="/" exact component={Controller} />
                    {/* <Route path="/errors/:error" exact component={Errors} key={key} /> */}
                    {/* <Route path="/_/path1/:path2?/:path3?/:path4?/:path5?/:path6?" exact component={Controller} key={key} /> */}
                    <Route path="/:areaName" exact component={Controller} key={key} />
                    <Route path="/:areaName/:pageName" exact component={Controller} key={key} />
                    <Route path="/:areaName/:pageName/:path3" exact component={Controller} key={key} />
                    <Route path="/:areaName/:pageName/:path3/:path4" exact component={Controller} key={key} />
                    <Route path="/:areaName/:pageName/:path3/:path4/:path5" exact component={Controller} key={key} />
                    <Route path="/:areaName/:pageName/:path3/:path4/:path5" exact component={Controller} key={key} />
                    <Route path="/:areaName/:pageName/:path3/:path4/:path5/:path6" exact component={Controller} key={key} />
                    <Route
                        path="/:areaName/:pageName/:path3/:path4/:path5/:path6/:path7"
                        exact
                        component={Controller}
                        key={key}
                    />
                    {/* <Route
                        path="/:areaName/:pageName/:route1/:route2/:route3/:route4/:route5"
                        exact
                        component={Controller}
                        key={key}
                    /> */}
                    {/* <Route
                        path="/:areaName/:pageName/:areaId/:secondaryPageName/:secondarySolutionId"
                        exact
                        component={Controller}
                        key={key}
                    /> */}
                    <Redirect to="/" />
                </Switch>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    search: state.search,
});

export default withRouter(connect(mapStateToProps, null)(PortalMain));
